.react-datepicker {
  color: #111827;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.react-datepicker__time-container,
.react-datepicker__time-box {
  width: 120px !important;
}

.react-datepicker__triangle,
.react-datepicker__header--time {
  display: none;
}

.react-datepicker__time-list-item {
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 10px;
}

li.react-datepicker__time-list-item--selected {
  background-color: #3b82f6 !important;
  font-weight: 400 !important;
}
