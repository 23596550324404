/* src/ActivityFeed/styles.css */
.inline-activity-feed-item {
  position: relative;
  z-index: 1;
  border-radius: 14px;
}
.inline-activity-feed-item::before {
  position: absolute;
  z-index: -1;
  inset: -2px;
  border-radius: 14px;
  background-color: #f3f4f6;
}

/* src/ReadMoreText/styles.css */
.break-word-dash {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

/* src/BoostIndicator/styles.css */
:root {
  --boost-animation-size: 60px;
}
.boost-animation {
  height: var(--boost-animation-size);
  width: var(--boost-animation-size);
  border-radius: 50%;
  background: radial-gradient(50% 50% at 50% 50%, rgba(236, 72, 153, 0) 0%, #ec4899 100%);
  position: absolute;
  z-index: 0;
  transform: scale(0);
  opacity: 0.5;
}
.boost-animation.boost-animate {
  animation: ping 2.8s linear infinite both;
}
.boost-animation.boost-delay-1 {
  animation-delay: 1.4s;
  z-index: 1;
}
@keyframes ping {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  10% {
    opacity: 0.5;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

/* src/CircularProgress/styles.css */
:root {
  --stroke-dashoffset-from: 371;
  --stroke-dashoffset-to: 0;
  --rotate-progress-dot: 0;
  --circular-progress-size: 140px;
}
.circular-progress {
  width: var(--circular-progress-size);
  height: var(--circular-progress-size);
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.circular-progress-indicator {
  animation: progress-animation 1s ease-out;
}
@keyframes progress-animation {
  from {
    stroke-dashoffset: var(--stroke-dashoffset-from);
  }
  to {
    stroke-dashoffset: var(--stroke-dashoffset-to);
  }
}
.circular-progress-content {
  transform: translateY(-5px);
}
.progress-dot {
  animation: dot-animation 1s ease-out forwards;
  transform-origin: 50% 50%;
}
@keyframes dot-animation {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(var(--rotate-progress-dot));
  }
}

/* src/InstructionsCard/styles.css */
:root {
  --instructions-transition-value: 0;
}
.instructions-card-container {
  transition: transform 0.3s ease-in-out;
  transform: translateY(var(--instructions-transition-value));
}

/* src/LeadStatusSlider/styles.css */
.swiper-slide {
  margin-right: 6px;
}
.swiper-slide:first-child {
  margin-left: 1rem;
}
.swiper-slide:last-child {
  margin-right: 1rem;
}

/* src/ProspectDetail/styles.css */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* src/RangeInput/styles.css */
:root {
  --custom-range-input-percentage: 0;
}
.custom-range-input {
  background-image: linear-gradient(#0a7aff, #0a7aff);
  background-size: var(--custom-range-input-percentage) 100%;
  background-repeat: no-repeat;
}
.custom-range-input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.custom-range-input::-moz-range-thumb {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

/* src/Search/components/styles.css */
.search-menu-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.search-menu-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}
.search-menu-scrollbar::-webkit-scrollbar-thumb {
  background-color: #9ca3af;
  border-radius: 4px;
  border: 1px solid #fff;
}
.search-menu-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #6b7280;
}

/* src/SubscriptionError/styles.css */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* src/TagsInput/tags-input.css */
.select-input__option:last-child {
  border: none;
}
.select-input__option:first-child {
  border-top: 1px solid #e5e7eb;
}
.select-input__multi-value__remove:hover {
  background-color: transparent !important;
}

/* src/Toast/styles.css */
.toast-transition {
  animation: move-in 0.3s ease-in-out both;
}
@keyframes move-in {
  0% {
    transform: translateY(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
