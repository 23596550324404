@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .h-500 {
    height: 500px;
  }
  .break-word-dash {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
}

:root {
  --banner-height: 0px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

nav .campaigns svg {
  margin-left: 0.5rem;
}

nav .automations svg {
  transform: rotate(-45deg);
  margin-top: -0.5rem;
  margin-left: 0.5rem;
}
