/* Container for the map and sidebar */
.prospects-map-container {
  height: 100vh;
  width: 100vw;
  position: relative;
}

/* Full-screen map styles */
.map-container {
  height: 100%;
  width: 100%;
  position: relative;
}

/* Marker styling */
.marker {
  position: absolute;
  width: 20px;
  height: 20px;
  color: red;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

/* Sliding window styles */
.sliding-window {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 1000;
  transform: translateX(0);
  transition: transform 0.3s ease-in-out;
}

.sliding-window .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
.search-box {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 1000;
  width: 400px; /* Increased width */
}

.search-icon {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0 10px;
}

.search-input {
  flex: 1;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.filter-link {
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.filter-link:hover {
  color: #0056b3;
  text-decoration: none;
}

.filter-button, .update-filter-date {
  cursor: pointer;
}

.filter-footer {
  padding: 10px;
}

.filter-container {
  padding-bottom: 0px!important;
}

.react-datepicker__input-container{
  display: none;
}

.prospects-date-container {
  position: relative;
}

.prospect-date-picker {
  position: absolute;
}

.mapboxgl-popup-content {
  background-color: #1f2937;
}

@keyframes pin-bounce {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  40% {
    opacity: 1;
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-3px);
  }
  60% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(0);
  }
}

#locationPin .drop {
  animation-name: pin-bounce;
  animation-duration: 0.5s;
}

.mapboxgl-popup-content {
  background-color: #1f2937;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: #1f2937;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: #1f2937;
}
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #1f2937;
}
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #1f2937;
}
