@media (min-width: 768px) {
  .location {
    max-width: 200px;
  }
}

.mapContainer {
  height: calc(100vh - 175px - var(--banner-height));
}

@media (min-width: 1024px) {
  .mapContainer {
    height: calc(100vh - 324px - var(--banner-height));
  }
}
