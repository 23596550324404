.wrap.note {
  width: 3.5in;
  height: 2.5in;
  padding: 0;
  overflow: hidden;
}

.wrap.postcard {
  width: 3.66in;
  height: 2in;
  padding: 0;
  overflow: hidden;
}
.frame.postcard {
  width: 11.25in;
  height: 6.25in;
  border: 1px solid black;
}
.frame.note {
  width: 6.75in;
  height: 4.75in;
  border: 1px solid black;
}

.frame.postcard {
  transform: scale(0.33);
  transform-origin: 0 0;
  -moz-transform: scale(0.33);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.33);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.33);
  -webkit-transform-origin: 0 0;
}

.frame.note {
  transform: scale(0.5);
  transform-origin: 0 0;
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}
